.collapsibleTable {
  margin: 0 1rem;
  width: 80%;

  .status {
    border-radius: 1rem;
    padding: 12px;
    width: 140px;
    text-align: center;
    display: inline-block;

    &.active {
      background-color: darkseagreen;
    }

    &.inactive {
      background-color: coral;
    }

    &.frozen {
      background-color: darkcyan;
    }
  }

  .statusHover {
    &:hover {
      background-color: bisque;
      cursor: pointer;
    }
  }

  .nameHover {
    padding-left: 0;
    padding-right: 0;

    &:hover {
      background-color: bisque;
      cursor: pointer;

      .editIcon {
        visibility: visible;
      }
    }

    .editIcon {
      visibility: hidden;
    }

    .nameSurname {
      width: 80%;
      padding-left: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .flex {
    display: flex;
  }
}
