.header {
    padding: 0.5rem 1rem;
    border-bottom: 0.15rem solid gray;
    display: flex;
    flex-direction: row;
    
    .headerItem {
        padding-right: .75rem;
    }
}
