.root {
  .disabled {
    opacity: 0.5;
  }

  .label {
    display: flex;
    margin-top: 0.5rem;
    padding: 0.5rem;
    width: fit-content;
    align-items: center;

    .nameHover {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}
