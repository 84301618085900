form {
  h3 {
    padding-bottom: 2rem;
  }
}

.container {
  h3 {
    padding-bottom: 2rem;
  }
  .generalInfo {
    display: flex;
    flex-direction: row;

    .leftBlock {
      .personalData {
        padding-top: 1.5rem;
        margin-right: 3rem;
        display: flex;
        flex-direction: column;

        label {
          line-height: 1.25rem;
          position: relative;
          top: -2rem;
          z-index: -1;
          font-size: 1rem;
          transition: all 120ms ease-in-out;

          &.notEmpty {
            top: -3rem;
            font-size: 0.675rem;
          }
        }

        input {
          line-height: 1.25rem;
          outline: 0;
          border-width: 0px 0px 2px;
          border-color: #999;
          background-color: transparent;

          &.error {
            border-color: red;
          }
        }

        input:focus {
          border-color: #3c65a1;

          + label {
            top: -3rem;
            font-size: 0.675rem;
          }

          &.error {
            border-color: red;
          }
        }
      }

      .status {
        margin-top: 1rem;
      }
    }
  }

  .radio {
    display: flex;

    .selected {
      color: #3c65a1;
    }

    label {
      margin-top: 3px;
    }

    input[type='radio'] {
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      margin: 6px 4px;
      font: inherit;
      color: #3c65a1;
      width: 1.375em;
      height: 1.375em;
      border: 0.175em solid #999;
      border-radius: 50%;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
    }

    input[type='radio']:checked {
      border: 0.175em solid #3c65a1;
    }

    input[type='radio']::before {
      content: '';
      width: 0.6em;
      height: 0.6em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em #3c65a1;
    }

    input[type='radio']:checked::before {
      transform: scale(1);
    }
  }

  .commentary {
    textarea {
      width: 100%;
      height: 100%;
      border-width: 0px 0px 2px;
      border-color: #999;
    }

    textarea:focus {
      border-color: #3c65a1;
      outline: 0;
    }

    label {
      display: block;
    }
  }

  .error {
    color: red;
  }

  .membershipTypeError {
    border: 1px solid red;
    padding: 0.25rem;
  }

  .membershipType {
    padding: 0.25rem;
    margin-left: 3rem;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}
