.pageTitle {
  width: 100%;
  font-weight: 500;
  font-size: 1.5rem;
  margin: 1rem 0 2rem;

  .title {
    padding: 1rem;
  }
}
