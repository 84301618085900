.searchBar {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;

  .searchBarSpan {
    display: block;
    width: 100%;

    input {
      border: 1px solid #e0e0e0;
      padding: 2px;
      margin: 2px;
      width: 100%;
    }

    input:focus {
      border: 1px solid #a0a0a0;
      outline: none !important;
    }
  }
}
