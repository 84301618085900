.container {
  display: flex;
  justify-content: space-between;

  .membershipTypeError {
    border: 1px solid red;
    padding: 0.25rem;
  }

  .membershipType {
    padding: 0.25rem;
  }
}

.newMembershipForm {
  .error {
    color: red;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
  }
}
