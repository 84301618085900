body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  label {
    margin-top: 5px;
  }

  .radio {
    display: flex;

    .selected {
      color: #3c65a1;
    }

    label {
      margin-top: 3px;
    }
    input[type='radio'] {
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      margin: 6px 4px;
      font: inherit;
      color: #3c65a1;
      width: 1.375em;
      height: 1.375em;
      border: 0.175em solid #999;
      border-radius: 50%;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
    }

    input[type='radio']:checked {
      border: 0.175em solid #3c65a1;
    }

    input[type='radio']::before {
      content: '';
      width: 0.6em;
      height: 0.6em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em #3c65a1;
    }

    input[type='radio']:checked::before {
      transform: scale(1);
    }
  }
}
input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  font-size: 16px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='submit'] {
  background-color: darkolivegreen;
  color: gold;
  font-size: 1.25rem;
  padding: 0.5rem 0.5rem;
  border-radius: 0.75rem;
  border: none;
  margin-bottom: 1rem;
  min-width: 100px;
}

input[type='reset'] {
  min-width: 100px;
  background-color: rgb(195, 116, 6);
  color: gold;
  font-size: 1.25rem;
  padding: 0.5rem 0.5rem;
  border-radius: 0.75rem;
  border: none;
  margin-bottom: 1rem;
}
