.filter {
  padding: 0 1rem;
  max-width: 150px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  label > span {
    font-size: 0.875rem;
  }
}
